import "react-perfect-scrollbar/dist/css/styles.css";

import { getAuth, onAuthStateChanged, onIdTokenChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { Suspense, useCallback, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  AuthProvider,
  FirestoreProvider,
  useFirebaseApp,
  useInitPerformance,
} from "reactfire";
import { RecoilRoot } from "recoil";

import { ChakraProvider, extendTheme } from "@chakra-ui/react";

import { GREY_COLOR, MAIN_COLOR } from "./constants/color";
import { PATHS } from "./constants/paths";
import { useConstructor } from "./hooks/useConstructor";
import { flattenRoutes, unauthorizedRoutes } from "./routes";
import { setUser } from "./state/userSlice";
import { initiateAxios } from "./utils/axiosInstance";
import { setToken } from "./utils/localStorage";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      retryDelay: 2000,
      cacheTime: 2000,
    },
  },
});

const customTheme = extendTheme({
  fonts: {
    heading: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";`,
    body: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";`,
  },
  styles: {
    global: () => ({
      body: {
        bg: MAIN_COLOR,
        color: GREY_COLOR,
      },
    }),
  },
});

const App: React.FC = () => {
  const firebaseApp = useFirebaseApp();
  const firestoreInstance = getFirestore(useFirebaseApp());
  const auth = getAuth(firebaseApp);

  const dispatch = useDispatch();

  useInitPerformance(
    async (firebaseApp) => {
      const { getPerformance } = await import("firebase/performance");
      return getPerformance(firebaseApp);
    },
    { suspense: false }, // false because we don't want to stop render while we wait for perf
  );

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (!user) {
        // navigate(PATHS.Login);
        return;
      }

      await updateUser();
    });

    onIdTokenChanged(auth, async (user) => {
      if (user) {
        setToken((user as any).accessToken);
        await updateUser();
      }
    });
  }, []);

  const updateUser = useCallback(async () => {
    const user = auth.currentUser;
    if (!user) return;
    const token = await user.getIdToken();
    setToken(token);
    dispatch(
      setUser({
        email: user.email || "",
        displayName: user.displayName || "",
        accessToken: token,
        photoURL: user.photoURL || "",
      }),
    );
  }, []);

  useConstructor(() => {
    initiateAxios();
  });

  return (
    <ChakraProvider theme={customTheme}>
      <RecoilRoot>
        <AuthProvider sdk={auth}>
          <FirestoreProvider sdk={firestoreInstance}>
            <QueryClientProvider client={queryClient}>
              <Suspense fallback={<></>}>
                <Routes>
                  {flattenRoutes.map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={route.component}
                    />
                  ))}
                  {unauthorizedRoutes.map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={route.component}
                    />
                  ))}
                  <Route
                    path="*"
                    element={<Navigate to={PATHS.Referral} replace />}
                  />
                </Routes>
              </Suspense>
            </QueryClientProvider>
          </FirestoreProvider>
        </AuthProvider>
      </RecoilRoot>
    </ChakraProvider>
  );
};

export default App;
