import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { FirebaseAppProvider } from "reactfire";

import App from "./app";
import firebaseConfig from "./configs/firebase";
import store from "./state/configureStore";
import { createRoot } from "react-dom/client";

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </FirebaseAppProvider>,
);
