/* eslint-disable @typescript-eslint/no-empty-function */
import { createInjectorsEnhancer } from "redux-injectors";

import { configureStore } from "@reduxjs/toolkit";

import { createReducer } from "./configureReducers";

const configureAppStore = () => {
  const store = configureStore({
    reducer: createReducer(),
    enhancers: [
      createInjectorsEnhancer({
        createReducer,
        runSaga: () => {},
      }),
    ],
  });

  return store;
};

const store = configureAppStore();
export default store;

export type AppDispatch = typeof store.dispatch;
