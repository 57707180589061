import { combineReducers } from "@reduxjs/toolkit";

import appReducer from "./appSlice";
import userReducer from "./userSlice";

export const createReducer = (injectedReducers = {}): any =>
  combineReducers({
    app: appReducer,
    user: userReducer,
    ...injectedReducers,
  });
