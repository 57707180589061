export const MAIN_COLOR = "#FFF3D9";
export const YELLOW_COLOR = "#E49F15";
export const WHITE_COLOR = "#FFF";
export const BROWN_LIGHT_COLOR = "#97441D";
export const GREY_COLOR = "#4F4F4F";
export const LINE_COLOR = "#C4C4C4";
export const TEXT_YELLOW_COLOR = "#BB8A52";
export const DARK_BEIGE_COLOR = "#C89D7E";
export const TERACOT_COLOR = "#B26F41";
export const TEXT_COLOR = "#D9D9D9";
export const TERACOT_LIGHT_COLOR = "#D7A272";
export const TEXT_GREY = "#A8A8A8";
