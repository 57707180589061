/* eslint-disable @typescript-eslint/ban-types */
import { useRef } from "react";

export const useConstructor = (constructor: Function): void => {
  const isConstructorCalled = useRef(false);
  if (!isConstructorCalled.current) {
    constructor();
    isConstructorCalled.current = true;
  }
};
