import axios, { AxiosError, AxiosResponse } from "axios";
import { useFirebaseApp } from "reactfire";

import { getAuth } from "@firebase/auth";

import { getToken, setToken } from "../utils/localStorage";

export const baseURL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "api";

declare module "axios" {
  export interface AxiosRequestConfig {
    retry?: boolean;
  }
}

type ErrorResponse = {
  code: 401 | 404;
  message: "token_expired" | "User not found";
};

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const initiateAxios = (): void => {
  const firebaseApp = useFirebaseApp();
  const auth = getAuth(firebaseApp);
  axiosInstance.interceptors.request.use(
    (req: any) => {
      if (getToken()) {
        req.headers["Authorization"] = `Bearer ${getToken()}`;
      }

      return req;
    },
    (err: AxiosError) => {
      return Promise.reject(err);
    },
  );

  axiosInstance.interceptors.response.use(
    (res: AxiosResponse) => {
      // Add configurations here

      return res;
    },
    async function (error: AxiosError<ErrorResponse>) {
      const originalRequest = error.config;
      if (!error || !error.response) {
        return;
      }

      const { message: errorMsg, code } = error.response.data;

      if (
        errorMsg &&
        errorMsg !== "token_expired" &&
        code !== 404 &&
        code !== 401
      ) {
        // message.error(errorMsg);
      }

      if (!auth || !auth.currentUser) {
        return Promise.reject(error.response.data);
      }

      if (error.response.status === 401 && !originalRequest.retry) {
        originalRequest.retry = true;
        const accessToken = await auth.currentUser.getIdToken();
        setToken(accessToken);
        return axiosInstance(originalRequest);
      }
      return Promise.reject(error);
    },
  );
};
