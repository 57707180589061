import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getToken } from "../utils/localStorage";

export interface UserState {
  email: string;
  accessToken: string;
  displayName: string;

  photoURL?: string;
}

const initialState: UserState = {
  email: "",
  displayName: "",

  accessToken: getToken(),
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserState>) {
      return {
        ...action.payload,
      };
    },
    setAccessToken(state, action: PayloadAction<string>) {
      state.accessToken = action.payload;
    },
  },
});

export const { setUser, setAccessToken } = userSlice.actions;

export default userSlice.reducer;
