import { lazy } from "react";

import { PATHS } from "../constants/paths";

// Unauthorized page
const LoginPage = lazy(() => import("../pages/login"));
const RegisterPage = lazy(() => import("../pages/register"));
const ResetPassword = lazy(() => import("../pages/resetPassword"));
const ForgetPassword = lazy(() => import("../pages/forgetPassword"));
const ReferralPage = lazy(() => import("../pages/referral"));

interface RouteProp {
  name: string;
  key: string;
  path?: PATHS;
  component?: React.ReactElement;
  redirect?: string;
  routes?: {
    name: string;
    key: string;
    path: string;
    redirect?: string;
    component: React.ReactElement;
  }[];
}

export const routes: RouteProp[] = [
  {
    path: PATHS.Referral,
    name: "Referral",
    key: "referral",
    component: <ReferralPage />,
  },
];

export const unauthorizedRoutes: RouteProp[] = [
  {
    path: PATHS.Login,
    name: "Login",
    key: "login",
    component: <LoginPage />,
  },
  {
    path: PATHS.Register,
    name: "Register",
    key: "register",
    component: <RegisterPage />,
  },
  {
    path: PATHS.ResetPassword,
    name: "ResetPassword",
    key: "reset-password",
    component: <ResetPassword />,
  },
  {
    path: PATHS.ForgetPassword,
    name: "ForgetPassword",
    key: "forget-password",
    component: <ForgetPassword />,
  },
];

export const flattenRoutes: Omit<RouteProp, "routes">[] = routes.reduce(
  (acc: any, { routes, ...rest }) => {
    acc = [...acc, rest];
    if (Array.isArray(routes)) {
      acc = [...acc, ...routes];
    }
    return acc;
  },
  [],
);
